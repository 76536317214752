// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sV d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sW d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sX d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sY d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sZ d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_s0 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_s1 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_s2 x_s2";
export var heroExceptionNormal = "v_s3 x_s3";
export var heroExceptionLarge = "v_s4 x_s4";
export var Title1Small = "v_s5 x_s5 x_ty x_tz";
export var Title1Normal = "v_s6 x_s6 x_ty x_tB";
export var Title1Large = "v_s7 x_s7 x_ty x_tC";
export var BodySmall = "v_s8 x_s8 x_ty x_tS";
export var BodyNormal = "v_s9 x_s9 x_ty x_tT";
export var BodyLarge = "v_tb x_tb x_ty x_tV";